import React, { useEffect, useRef, useState } from "react";
import "../../App.css";
import axios from "axios";
import { useTextToVoice } from "react-speakup";
import iconsend from "../../send.png";
import vid from "../../one.mp4";

import { initializeApp } from "@firebase/app";
import { getDatabase } from "firebase/database";
import { ref, set, get, update, remove, child } from "firebase/database";

function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyD6Bk3_YGDxOk1lKSno_RCdx6gcTaDMhHg",
    authDomain: "fire-8def1.firebaseapp.com",
    databaseURL: "https://fire-8def1-default-rtdb.firebaseio.com",
    projectId: "fire-8def1",
    storageBucket: "fire-8def1.appspot.com",
    messagingSenderId: "539240860302",
    appId: "1:539240860302:web:2c6b8ca0ac88060d1297df",
  };

  const app = initializeApp(firebaseConfig);
  return getDatabase(app);
}

const database = StartFirebase();
const db = database;
const dbref = ref(db);

export default function Launch() {
  let counter = 0;
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      async function fetchData() {
        try {
          get(child(dbref, `login/`)).then((snapshot) => {
            const datas = snapshot.val();

            const output = datas?.isLogins;

            if (output === 1) {
              setFlag(true);
              counter = 0;
            }

            if (flag === false) {
              if (counter === 0) {
                set(ref(db, `login/`), {
                  isLogins: 0,
                });
              }
              counter++;
            }
          });
        } catch (error) {
          console.error(error);
        }
      }
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div class="body">
        <div class="one">
          {flag && (
            <div className="videoo">
              <video
                width="1920"
                height="540"
                autoPlay={"autoplay"}
                onEnded={(e) => setFlag(false)}
              >
                <source src={vid} type="video/mp4" />
              </video>
            </div>
          )}
          <div class="two">
            <div class="cardso">
              <div class="three"></div>

              <div class="img_conto">
                <img
                  src="https://i.ibb.co/YZPVrvm/crdblogo.png"
                  alt="crdblogo"
                  class=""
                />
                <span class="online_icon"></span>
              </div>
              <p className="lauc">CRDB LAUNCHING ACTION</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// update(ref(db, `chatbot/`), {
//   Litrestwo: datas.meter?.Litrestwo

// })
